import { BuddyDesktopMic7G } from "src/app/model/input/handlers/BuddyDesktopMic7G";
import { Delcom706501Handler } from "src/app/model/input/handlers/Delcom706501Handler";
import { HA11Handler } from "src/app/model/input/handlers/HA11Handler";
import { ImtraDexHandler } from "src/app/model/input/handlers/ImtraDexHandler";
import { InfinityHandler } from "src/app/model/input/handlers/InfinityHandler";
import { OuterplaneCustom } from "src/app/model/input/handlers/OuterplaneCustom";
import { PeiTelHandler } from "src/app/model/input/handlers/PeiTelHandler";
import { PS12Handler } from "src/app/model/input/handlers/PS12Handler";
import { SpeakerBox } from "src/app/model/input/handlers/SpeakerBox";
import type { RecognizedUsbDevice } from "src/app/model/input/RecognizedUsbDevice";

export const UsbDevices: RecognizedUsbDevice[] = [
  { Handler: Delcom706501Handler, productId: 45184, vendorId: 4037 },
  { Handler: InfinityHandler, productId: 255, vendorId: 1523 },
  { Handler: PeiTelHandler, productId: 13686, vendorId: 1869 },
  { Handler: PS12Handler, productId: 256, vendorId: 11054 },
  { Handler: PS12Handler, productId: 272, vendorId: 11054 },
  { Handler: PS12Handler, productId: 288, vendorId: 11054 },
  { Handler: PS12Handler, productId: 304, vendorId: 11054 },
  { Handler: PeiTelHandler, productId: 544, vendorId: 11054 },
  { Handler: PeiTelHandler, productId: 560, vendorId: 11054 },
  { Handler: HA11Handler, productId: 1024, vendorId: 11054 },
  { Handler: HA11Handler, productId: 1040, vendorId: 11054 },
  { Handler: ImtraDexHandler, productId: 5136, vendorId: 1394 },
  { Handler: ImtraDexHandler, productId: 277, vendorId: 46626 },
  { Handler: ImtraDexHandler, productId: 268, vendorId: 8886 },
  { Handler: BuddyDesktopMic7G, productId: 29205, vendorId: 6003 },
  { Handler: OuterplaneCustom, productId: 19, vendorId: 3468 },
  { Handler: OuterplaneCustom, productId: 1503, vendorId: 5824 },
  { Handler: SpeakerBox, productId: 64203, vendorId: 4642 },
];
